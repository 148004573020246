import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAVc6xd2t4R_zrDRMw3qfsGmtKYWfpvLQM",
  authDomain: "ugl-trucks-map.firebaseapp.com",
  projectId: "ugl-trucks-map",
  storageBucket: "ugl-trucks-map.appspot.com",
  messagingSenderId: "115237863500",
  appId: "1:115237863500:web:6c313ae7372bdf029db4e9"
};

// eslint-disable-next-line no-unused-vars
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);