import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FileBase from "react-file-base64";

import useStyles from "./styles";
import { createPost, updatePost } from "../../actions/posts";

const Form = ({ currentId, setCurrentId }) => {
  const [postData, setPostData] = useState({
    creator: "",
    message: "",
    selectedFile: "",
    name: "",
    price: "",
    contactNumber: "",
    email: "",
    engine: "",
    year: "",
    color: "",
    power: "",
    condition: false,
    milage: ""
  });
  const post = useSelector((state) =>
    currentId ? state.posts.find((message) => message._id === currentId) : null
  );
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (post) setPostData(post);
  }, [post]);

  const clear = () => {
    setCurrentId(0);
    setPostData({
      creator: "",
      selectedFile: "",
      name: "",
      price: "",
      contactNumber: "",
      email: "",
      engine: "",
      year: "",
      color: "",
      power: "",
      condition: false,
      milage: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentId === 0) {
      dispatch(createPost(postData));
      clear();
    } else {
      dispatch(updatePost(currentId, postData));
      clear();
    }
  };

  return (
    <Paper className={classes.paper}>
      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        onSubmit={handleSubmit}
      >
        <Typography className={classes.title} variant="h6">
          {currentId ? `Editing "${post.name}"` : "Creating a Post"}
        </Typography>
        <TextField
          name="creator"
          variant="outlined"
          label="Creator"
          fullWidth
          value={postData.creator}
          onChange={(e) =>
            setPostData({ ...postData, creator: e.target.value })
          }
        />
        <TextField
          name="name"
          variant="outlined"
          label="Name*"
          fullWidth
          value={postData.name}
          onChange={(e) => setPostData({ ...postData, name: e.target.value })}
        />
        <TextField
          name="price"
          variant="outlined"
          label="Price (USD)**"
          fullWidth
          value={postData.price}
          onChange={(e) => setPostData({ ...postData, price: e.target.value })}
        />
        <TextField
          name="number"
          variant="outlined"
          label="Contact number"
          fullWidth
          value={postData.contactNumber}
          onChange={(e) =>
            setPostData({ ...postData, contactNumber: e.target.value })
          }
        />
        <TextField
          name="email"
          variant="outlined"
          label="Email"
          fullWidth
          value={postData.email}
          onChange={(e) =>
            setPostData({ ...postData, email: e.target.value })
          }
        />
        <TextField
          name="engine"
          variant="outlined"
          label="Engine"
          fullWidth
          value={postData.engine}
          onChange={(e) => setPostData({ ...postData, engine: e.target.value })}
        />
        <TextField
          name="year"
          variant="outlined"
          label="Year"
          fullWidth
          value={postData.year}
          onChange={(e) => setPostData({ ...postData, year: e.target.value })}
        />
        <TextField
          name="color"
          variant="outlined"
          label="Color"
          fullWidth
          value={postData.color}
          onChange={(e) => setPostData({ ...postData, color: e.target.value })}
        />
        <TextField
          name="power"
          variant="outlined"
          label="Power (Horse Power)**"
          fullWidth
          value={postData.power}
          onChange={(e) => setPostData({ ...postData, power: e.target.value })}
        />
        <div className={classes.flex}>
          <p
            style={{ fontWeight: "700", color: "304659" }}
            className={classes.title}
          >
            Is new ?
          </p>
          <input
            type="checkbox"
            name="new"
            checked={postData.condition}
            onChange={(e) =>
              setPostData({ ...postData, condition: e.target.checked })
            }
          />
        </div>
        <TextField
          name="milage"
          variant="outlined"
          label="Milage (mi)**"
          fullWidth
          value={postData.milage}
          onChange={(e) => setPostData({ ...postData, milage: e.target.value })}
        />
        <p style={{color: 'red'}}>* - must be filled<span style={{margin: '20px'}}></span>** - must be a number</p>
        <div className={classes.fileInput}>
          <FileBase
            className={classes.title}
            id="overPost"
            type="file"
            multiple={false}
            onDone={({ base64 }) =>
              setPostData({ ...postData, selectedFile: base64 })
            }
          />
        </div>
        <Button
          className={classes.buttonSubmit}
          variant="contained"
          color=""
          size="large"
          type="submit"
          fullWidth
        >
          Submit
        </Button>
        <Button
          className={classes.buttonClear}
          variant="contained"
          color=""
          size="small"
          onClick={clear}
          fullWidth
        >
          Clear
        </Button>
      </form>
    </Paper>
  );
};

export default Form;
