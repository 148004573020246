const FilterBarLogic = ({
  setCost,
  setFilter,
  setCondition,
  cost,
  filter,
  condition,
}) => {
  const setCostOnClick = (costVar) => {
    setCondition(0);
    setFilter(0);
    cost === costVar ? setCost(0) : setCost(costVar);
  };

  const setFilterOnClick = (filterVar) => {
    setCondition(0);
    setCost(0);
    filter === filterVar ? setFilter("") : setFilter(filterVar);
  };

  const activeButtonCheck = (propVar, value) => {
    if (propVar === value) {
      return "active";
    }
  };

  return (
    <>
      <div className="sorting">
        <h3>Sort</h3>
        <h5>By price</h5>
        <div className="sorting__buttons">
          <input
            // className={cost === 1 ? "sorting__buttons__button active" : "sorting__buttons__button"}
            className={`sorting__buttons__button ${activeButtonCheck(cost, 1)}`}
            type="submit"
            value="High cost first"
            onClick={() => setCostOnClick(1)}
            // onClick={() => {
            //   setCondition(0);
            //   setFilter(0);
            //   cost ? setCost(0) : setCost(1);
            // }} и снизу так в каждом из <input>
          />
          <input
            // className={cost === 2 ? "sorting__buttons__button active" : "sorting__buttons__button"}
            className={`sorting__buttons__button ${activeButtonCheck(cost, 2)}`}
            type="submit"
            value="Low cost first"
            onClick={() => setCostOnClick(2)}
          />
        </div>
        <h5>By milage</h5>
        <div className="sorting__buttons">
          <input
            // className={cost === 3 ? "sorting__buttons__button active" : "sorting__buttons__button"}
            className={`sorting__buttons__button ${activeButtonCheck(cost, 3)}`}
            type="submit"
            value="High first"
            onClick={() => setCostOnClick(3)}
          />
          <input
            // className={cost === 4 ? "sorting__buttons__button active" : "sorting__buttons__button"}
            className={`sorting__buttons__button ${activeButtonCheck(cost, 4)}`}
            type="submit"
            value="Low first"
            onClick={() => setCostOnClick(4)}
          />
        </div>
      </div>
      <div className="filtering">
        <h3>Filter</h3>
        <h5>Manufacturer</h5>
        <input
          // className={filter === 'Volvo' ? "filtering__button active" : "filtering__button"}
          className={`filtering__button ${activeButtonCheck(filter, "Volvo")}`}
          type="submit"
          value="Volvo"
          onClick={() => {
            setFilterOnClick("Volvo");
          }}
          // onClick={() => {
          //   setCondition(0);
          //   setCost(0);
          //   filter ? setFilter('') : setFilter("Volvo");
          // }}
        />
        <input
          // className={filter === 'Kenworth' ? "filtering__button active" : "filtering__button"}
          className={`filtering__button ${activeButtonCheck(
            filter,
            "Kenworth"
          )}`}
          type="submit"
          value="Kenworth"
          onClick={() => {
            setFilterOnClick("Kenworth");
          }}
          // onClick={() => {
          //   setCondition(0);
          //   setCost(0);
          //   filter ? setFilter('') : setFilter("Kenworth");
          // }}
        />
        <input
          // className={filter === 'Peterbilt' ? "filtering__button active" : "filtering__button"}
          className={`filtering__button ${activeButtonCheck(
            filter,
            "Peterbilt"
          )}`}
          type="submit"
          value="Peterbilt"
          onClick={() => {
            setCondition(0);
            setCost(0);
            filter ? setFilter("") : setFilter("Peterbilt");
          }}
        />
        <h5>Condition</h5>
        <input
          className={
            condition ? "filtering__button active" : "filtering__button"
          } //"filtering__button active"
          type="submit"
          value="Used"
          onClick={() => {
            setCost(0);
            setFilter(0);
            condition ? setCondition(0) : setCondition(1);
          }}
        />
      </div>
    </>
  );
};

export default FilterBarLogic;
