import React from 'react';
import './item.styles.scss';

import { Card, CardMedia } from '@material-ui/core';

const Item = ({key, name, price, contactNumber, email, engine, year, power, color, condition, milage, selectedFile}) => {
    const hrefLink = `mailto:${email}@example.com?subject=Post in UGL Trucks website`
    const hrefNumber = `tel:${contactNumber}`
    return (
        <div className="item">
            <div className="img">   
            <Card>
                <CardMedia className='img__pic' style={{}} image={selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} name={name} />
            </Card>
            </div>
            <div className="desc">
            <h3>{name}</h3>
            <div className="desc__info">
                 <ul >
                     <li><b>Engine:</b> {engine}</li>
                     <li><b>Year:</b> {year}</li>
                     <li><b>Horse Power:</b> {power}</li>
                     <li><b>Color:</b> {color}</li>
                     <li><b>Condition:</b> {condition ? 'New' : 'Used'}</li>
                     <li><b>Milage:</b> {milage}</li>
                     <li><b>Price:</b> <span className='price'>${price}</span></li>
                     <li><b>Contact Number:</b> {contactNumber}</li>
                     <li><b>Email:</b> {email}</li>
                </ul>
            </div>
            <p className="desc__contact">
             <a href={hrefLink}>Email a Seller</a>
             <a href={hrefNumber}>Call a Seller</a>
            </p>

            </div>
            
        </div>
    )
}

export default Item;