import React, { useRef } from "react";
import emailjs from "emailjs-com";

import "./contact-form.styles.scss";

const ContactForm = () => {
  const form = useRef();

   const sendEmail = (e) => {
     e.preventDefault()

    emailjs
      .sendForm("service_9ag5qpu", "template_revfebh", form.current, "cTFusmlbbi6oj4dfP")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
     e.target.reset()
  };
  return (
    <form ref={form} className="contact-form" onSubmit={() => sendEmail()}>
      <input
        name="name"
        type="text"
        className="feedback-input"
        placeholder="Name"
      />
      <input
        name="email"
        type="text"
        className="feedback-input"
        placeholder="Email"
      />
      <textarea
        name="message"
        className="feedback-input"
        placeholder="Comment"
      ></textarea>
      <input type="submit" value='SUBMIT'/>
    </form>
  );
};

export default ContactForm;
