import React from "react";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import "./about.styles.scss";

const AboutPage = () => {
  return (
    <div className="about">
      <Header />
      <main>
        <div className="about__info">
        Welcome to UGL truck sales, your number one used truck sales. We’re dedicated to giving you the very best of our services and used trucks, with focus on to build trusted relationships and best  customer service. Founded in 2021 by FARHOD M & AZIK H, ( UGL truck sales ) has come a long way from its beginnings in a Oswego Illinois office. When UGl Truck Sales first started out, our passion was to those company truck drivers to give an opportunity, become an owner operator and achieve their dreams by providing the best equipments for our fellow truck driver, became our main priority and give them the impetus to turn their hard work and inspiration in to a real owner operator. Now we serve customers in our  Montgomery IL location and thrilled to be part of the their dream. We hope you enjoy our products as much as we enjoy offering them to you, if you have any question or comments please do not hesitate to contact us.
        </div>
      </main>
      <Footer/>
    </div>
  );
};

export default AboutPage;
