import React from "react";
import QuickSearch from "../quick-search/quick-search.component";
import "./filter-bar-mob.styles.scss";
import FilterBarLogic from "../filter-bar-logic/filter-bar-logic.component";

const FilterBarForMob = ({ setCost, setFilter, setCondition, setSearch, cost, filter, condition  }) => {
  return (
    <div className="filter-bar-mob">
      <QuickSearch setSearch={setSearch} />
      <FilterBarLogic
        setCost={setCost}
        setFilter={setFilter}
        setCondition={setCondition}
        cost={cost}
        filter={filter}
        condition={condition}
      />
      
    </div>
  );
};

export default FilterBarForMob;
