import React, { useState } from "react";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth } from "../../firebase-config";

import "./sign-in.styles.scss";

const SignIn = ({ user, setUser, admin, setAdmin }) => {
  //*
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const login = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
     
      adminCheck();
    } catch (error) {
       setErrorMessages(error);
    }
  };

  const adminCheck = () => {
    if (loginEmail === "sales@ugltruck.com" && loginPassword === "adminsecurity") {
      setAdmin(true)
    }
    else {
      setAdmin(false)
    }
  };

  const logout = async () => {
    setAdmin(false);
    setUser({});
    await signOut(auth);
  };

  const [errorMessages, setErrorMessages] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    login();
  };

  // Generate JSX code for error message
  const renderErrorMessage = () => (
    <div className="error">{errorMessages.message?.slice(10)}</div>
  );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <div className="title-sub">If you already have an account</div>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Email </label>
          <input
            type="text"
            name="email"
            onChange={(e) => {
              setLoginEmail(e.target.value);
            }}
            required
          />
        </div>
        <div className="input-container">
          <label>Password </label>
          <input
            type="password"
            name="password"
            onChange={(e) => {
              setLoginPassword(e.target.value);
            }}
            required
          />
          {renderErrorMessage()}
        </div>
        <div className="button-container">
          <input type="submit" value="LOG IN" />
        </div>
      </form>
    </div>
  );

  const adminLink = (
    <> as admin
      <div>
      <a href="/admin123" className="admin-link">
            <i>Manage website here</i>
          </a>
      </div>
    </>
  )

  return (
    <div className="login-form">
      <div className="title">Sign In</div>
      {user ? (
        <div>
          {user.email} - is successfully logged in {admin ? adminLink : ''}<br />
          <div>
          <input type="submit" value="LOG OUT" onClick={logout}/>
        </div>
        </div>
      ) : (
        renderForm
      )}
    </div>
  );
};

export default SignIn;
