import React from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose } from "redux";
//* redux recommends to switch to some new method of createStore
import { legacy_createStore as createStore} from 'redux'
import thunk from "redux-thunk";

import { reducers } from "./reducers";
import App from "./App";
import "./index.css";

const PostManager = ({admin}) => {
  const store = createStore(reducers, compose(applyMiddleware(thunk)));

  return (
    <div>
      <Provider store={store}>
        {admin ? <App /> : <div className="no-access">No access to this page</div>}
      </Provider>
    </div>
  );
};

export default PostManager;
