import "./footer.styles.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="columns">
        <div className="links-contact-list">
          <ul>
            <h3>USEFUL LINKS</h3>
            <li><a href="/#inventory">Inventory</a></li>
            <li><a href="/auth">Sign in</a></li>
            <li><a href="/about">About</a></li>
          </ul>
          <ul>
            <h3>CONTACT INFO</h3>
            <li>sales@ugltruck.com</li>
            <li>Illinois, Montgomery</li>
            <li>7709004040</li>
          </ul>
        </div>
        <div className="copyright">
          <p>@2022 COPYRIGHT</p>
          <p>
            Website created by{" "}
            <span className="portfolio-link">
              <a href="https://aasil.netlify.app/" alt="create portfolio link">
                <i>Asil</i>
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
