import React, { useEffect, useState } from "react";
import Item from "../item/item.component";

// import mockTrucks from "../../assets/mock-data"; // mock data if needed for testing
import "./items.styles.scss";

const Items = ({ cost, filter, condition, search }) => {
  const [mockTrucks, setTrucks] = useState();

  const callBackendAPI = async () => {
  //   // const response = await fetch("http://localhost:5000/posts");
    const response = await fetch("https://ugl-trucks-server.onrender.com/posts");
  //   // const response = await fetch("https://ugl-truck.herokuapp.com/posts"); old api 
  //   //! not ugl-truckS

    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  useEffect(() => {
    callBackendAPI()
      .then((res) => setTrucks(res))
      .catch((err) => console.log(err));
  }, []);

  switch (cost) {
    case 1:
      mockTrucks.sort((x, y) => {
        return y.price - x.price;
      });
      break;
    case 2:
      mockTrucks.sort((x, y) => {
        return x.price - y.price;
      });
      break;
    case 3:
      mockTrucks.sort((x, y) => {
        return y.milage - x.milage;
      });
      break;
    case 4:
      mockTrucks.sort((x, y) => {
        return x.milage - y.milage;
      });
      break;
    default:
      break;
  }

  if (mockTrucks) {
    const filteredTrucks = mockTrucks.filter((x) => x.name.includes(filter));
    let usedTrucks;
    if (condition) {
      usedTrucks = mockTrucks.filter((x) => x.condition === false);
    }
    return filter
      ? filteredTrucks.map(({ id, ...otherMockProps }) => (
          <Item key={id} {...otherMockProps} cost={cost} />
        ))
      : condition
      ? usedTrucks.map(({ id, ...otherMockProps }) => (
          <Item key={id} {...otherMockProps} cost={cost} />
        ))
      : mockTrucks
          // eslint-disable-next-line array-callback-return
          .filter((e) => {
            if (search === "") {
              return e;
            } else if (e.name.toLowerCase().includes(search.toLowerCase())) {
              return e;
            }
          })
          .map(({ id, ...otherMockProps }) => (
            <Item key={id} {...otherMockProps} cost={cost} />
          ));
  } else {
    return <div className="loading">Loading ...</div>
  }
};

export default Items;
