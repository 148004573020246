import React from "react";
import FilterBarLogic from "../filter-bar-logic/filter-bar-logic.component";
import QuickSearch from "../quick-search/quick-search.component";
import "./filter-bar.styles.scss";

const FilterBar = ({
  setCost,
  setFilter,
  setCondition,
  setSearch,
  cost,
  filter,
  condition,
}) => {

  return (
    <div className="filter-bar">
      <p className="inventory__title">Inventory</p>
      <QuickSearch setSearch={setSearch} />
      <FilterBarLogic
        setCost={setCost}
        setFilter={setFilter}
        setCondition={setCondition}
        cost={cost}
        filter={filter}
        condition={condition}
      />
    </div>
  );
};

export default FilterBar;
