import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { deletePost } from '../../../actions/posts';
import useStyles from './styles';

const Post = ({ post, setCurrentId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} name={post.name} />
      <div className={classes.overlayPost}>
        <Typography id='overPost' variant="h6">{post.creator}</Typography>
        <Typography id='overPost' variant="h5">{post.name}</Typography>
        <Typography id='overPost' variant="body2">{moment(post.createdAt).fromNow()}</Typography>
      </div>
      <div className={classes.overlay2Post}>
        <Button size="small" id='overPost1'  onClick={() => setCurrentId(post._id)}><MoreHorizIcon fontSize="default" /></Button>
      </div>
      <CardContent>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Price: ${post.price}</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Contact Number: {post.contactNumber}</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Email: {post.email}</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Engine: {post.engine}</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Year: {post.year}</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Color: {post.color}</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Power: {post.power} HP</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Condition: {post.condition ? 'new' : 'used'}</Typography>
        <Typography className={classes.color} variant="body2" color="textSecondary" component="p">Milage: {post.milage} mi</Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Button className={classes.btn} size="small" color="primary" onClick={() => dispatch(deletePost(post._id))}><DeleteIcon className={classes.color} fontSize="small" /> Delete</Button>
      </CardActions>
    </Card>
  );
};

export default Post;
