import React, {useState,  useEffect} from "react";
import HomePage from "./pages/homepage/homepage.component";
import AboutPage from "./pages/about/about.component";
import PostManager from "./pages/post-manager/post-manager.component";
import Auth from "./pages/auth/auth.component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.scss';

function App() {
  const [admin, setAdmin] = useState(0);

  useEffect(() => {
    setAdmin(JSON.parse(window.sessionStorage.getItem("admin")));
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("admin", admin);
  }, [admin]);
 

  return (
    <div className="App">
      <BrowserRouter>
        {<Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/about" element={<AboutPage />}></Route>
          <Route path="/admin123" element={<PostManager admin={admin}/>}></Route>
          <Route path="/auth" element={<Auth setAdmin={setAdmin} admin={admin}/>}></Route>
        </Routes>}
      </BrowserRouter>
    </div>
  );
}

export default App;
