import React, {useState} from "react";
import Header from "../../components/header/header.component";
import SignIn from "../../components/sign-in/sign-in.component";
import SignUp from "../../components/sign-up/sign-up.component";
import Footer from "../../components/footer/footer.component";
import './auth.styles.scss';

const Auth = ({admin, setAdmin}) => {
  const [user, setUser] = useState({});
  
  return(
  <div className="auth">
    <Header />
    <main>
      <div className="auth__forms">
        <SignIn setUser={setUser} user={user} setAdmin={setAdmin} admin={admin}/>
        <SignUp setUser={setUser}/>
      </div>
    </main>
    <Footer/>
  </div>
)};

export default Auth;
