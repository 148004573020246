import React, { useState } from "react";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../firebase-config";

const SignUp = ({setUser }) => {
  const [errorMessages, setErrorMessages] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  const register = async () => {
    try {
      // eslint-disable-next-line no-unused-vars
      const user = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
    } catch (error) {
      setErrorMessages(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    register();
  };

  const renderErrorMessage = () => (
    <div className="error">{errorMessages.message?.slice(10)}</div>
  );

  return (
    <div className="login-form">
      <div className="title">Sign Up</div>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Email </label>
            <input
              type="text"
              name="uname"
              onChange={(e) => {
                setRegisterEmail(e.target.value);
              }}
              required
            />
          </div>
          <div className="input-container">
            <label>Password </label>
            <input
              type="password"
              name="pass"
              onChange={(e) => {
                setRegisterPassword(e.target.value);
              }}
              required
            />
            {renderErrorMessage()}
          </div>
          <div className="button-container">
            <input type="submit" value="SING UP" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
